import {useState} from 'react';
import * as Toast from "@radix-ui/react-toast";
import Header from "../Header/header";
import Banner from "../Banner/banner";
import BrandSlider from "../Brand/brand";
import About from "../About/about";
import ServiceList from "../Service/service";
import SkillList from "../Skills/skill";
import Work from "../Work/work";
import Testimonial from "../Testimonial/testimonial";
import Contact from "../Contact/contact";
import Footer from "../Footer/footer";
import Intrested from "../Intrested/intrested";

/*--------------------
* Building NoCodeDone
----------------------*/
export default function NoCodeDon() {
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [toastOpen, setToastOpen] = useState(false); 

  const handleToast = (message, status) => {
    setToastMessage(message);
    setToastStatus(status);
    setToastOpen(true); // Open the toast
  };

  return (    
    <>
      <Toast.Provider>
        <Header />
        <main className="wrapper">
          <Banner handleToast={handleToast}/>
          <BrandSlider />
          <About />
          <ServiceList />
          <SkillList />
          <Intrested />
          <Work />
          <Testimonial />
          <Contact handleToast={handleToast}/>
        </main>
        <Footer />
        <Toast.Root
          className="bg-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
          open={toastOpen}
          onOpenChange={setToastOpen}
        >
          <Toast.Title className="[grid-area:_title] mb-[5px] font-medium text-slate12 text-[15px]">
            {toastStatus === "success" ? "Success!" : "Error"}
          </Toast.Title>
          <Toast.Description className="[grid-area:_description] m-0 text-slate11 text-[13px] leading-[1.3]">
            {toastMessage}
          </Toast.Description>
          <Toast.Close onClick={() => setToastOpen(false)} />
        </Toast.Root>
        <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
      </Toast.Provider>
    </>
  );
}
