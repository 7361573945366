import { useState } from "react";

/*--------------------
* Skill Section
----------------------*/
function Skill(props) {
  const isImage = /\.(png|jpg|jpeg|svg|gif)$/i.test(props.id?.icon);

  return (
    <>
      <div className="col-span-6">
        <div className={props.id.main + " feature-box-02 "}>
          <div className="icon">
            {isImage ? (
              <img
                src={props.id.icon}
                alt={props.title}
                className="skill-logo"
              />
            ) : (
              <i className={props.id?.icon}></i>
            )}
          </div>
          <h6>{props.title}</h6>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Skill List Section
----------------------*/
export default function SkillList() {
  const [activeTab, setActiveTab] = useState("nocode");

  const tailwind = "/img/logos/tailwind.svg";
  const mysql = "/img/logos/mysql.svg";
  const postgresql = "/img/logos/postgres.svg";
  const firebase = "/img/logos/firebase.svg";
  const flask = "/img/logos/flask.svg";
  const nextjs = "/img/logos/nextjs.svg";
  const typescript = "/img/logos/typescript.svg";
  const mongodb = "/img/logos/mongodb.svg";


  const developmentSkills = [
    { id: { main: "bg-1", icon: "fab fa-html5" }, title: "HTML5" },
    { id: { main: "bg-2", icon: "fab fa-css3" }, title: "CSS3" },

    { id: { main: "bg-3", icon: tailwind }, title: "Tailwind CSS" },
    { id: { main: "bg-4", icon: "fab fa-js" }, title: "JavaScript" },
    { id: { main: "bg-5", icon: "fab fa-react" }, title: "React" },

    { id: { main: "bg-6", icon: typescript }, title: "TypeScript" },
    { id: { main: "bg-1", icon: nextjs }, title: "Next.js" },
    { id: { main: "bg-2", icon: "fab fa-node" }, title: "Node.js" },

    { id: { main: "bg-3", icon: "fab fa-python" }, title: "Python" },

    { id: { main: "bg-4", icon: flask }, title: "Flask" },
    { id: { main: "bg-5", icon: "fab fa-php" }, title: "PHP" },

    { id: { main: "bg-6", icon: mysql }, title: "MySQL" },

    { id: { main: "bg-1", icon: postgresql }, title: "PostgreSQL" },

    { id: { main: "bg-2", icon: mongodb }, title: "MongoDB" },

    { id: { main: "bg-3", icon: firebase }, title: "Firebase" },
  ];

  const zapier = "/img/logos/zapier.svg";
  const make = "/img/logos/make.svg";
  const airtable = "/img/logos/airtable.svg";
  const softr = "/img/logos/softr.svg";
  const botpress = "/img/logos/botpress.svg";

  const noCodeSkills = [
    {
      id: { main: "bg-1", icon: make },
      title: "Make (Integromat)",
    },
    {
      id: { main: "bg-2", icon: zapier },
      title: "Zapier",
    },
    {
      id: { main: "bg-3", icon: botpress },
      title: "Botpress",
    },
    {
      id: { main: "bg-4", icon: airtable },
      title: "Airtable",
    },
    {
      id: { main: "bg-5", icon: softr },
      title: "Softr",
    },
  ];

  const webflow = "/img/logos/webflow.svg";
  const shopify = "/img/logos/shopify.svg";
  const framer = "/img/logos/framer.svg";
  const webBuilderSkills = [
    {
      id: { main: "bg-1", icon: webflow },
      title: "Webflow",
    },
    { id: { main: "bg-2", icon: "fab fa-wix" }, title: "Wix" },
    {
      id: { main: "bg-3", icon: shopify },
      title: "Shopify",
    },
    { id: { main: "bg-4", icon: "fab fa-wordpress" }, title: "WordPress" },
    { id: { main: "bg-5", icon: "fab fa-squarespace" }, title: "Squarespace" },
    { id: { main: "bg-6", icon: framer }, title: "Framer" },
  ];

  return (
    <>
      <section
        data-scroll-data="2"
        id="skill"
        className="section experience-section"
      >
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1">
            <div className="col-span-12 lg:pl-9">
              <div className="section-heading">
                <h3 className="m-0">
                  <span>Skills</span>
                </h3>
              </div>

              <div className="mb-8 tabs flex space-x-4">
                <button
                  className={`tab-button px-4 py-2 hover:bg-slate-600 hover:text-white ${
                    activeTab === "nocode" ? "text-white bg-black" : "text-black bg-gray-200"
                  }`}
                  onClick={() => setActiveTab("nocode")}
                >
                  NoCode Tools
                </button>

                <button
                  className={`tab-button px-4 py-2  hover:bg-slate-600 hover:text-white ${
                    activeTab === "webbuilder"
                      ? "text-white bg-black"
                      : "text-black bg-gray-200"
                  }`}
                  onClick={() => setActiveTab("webbuilder")}
                >
                  Web Builders
                </button>

                <button
                  className={`tab-button px-4 py-2  hover:bg-slate-600 hover:text-white ${
                    activeTab === "development"
                      ? "text-white bg-black"
                      : "text-black bg-gray-200"
                  }`}
                  onClick={() => setActiveTab("development")}
                >
                  Development
                </button>
              </div>
              <hr className="mb-8" />
              {activeTab === "development" && (
                <div className="skill-box">
                  <div className="grid gap-4 grid-cols-12">
                    {developmentSkills.map((val, i) => {
                      return <Skill key={i} id={val.id} title={val.title} />;
                    })}
                  </div>
                </div>
              )}

              {activeTab === "nocode" && (
                <div className="skill-box">
                  <div className="grid gap-4 grid-cols-12">
                    {noCodeSkills.map((skill, i) => {
                      return (
                        <Skill key={i} id={skill.id} title={skill.title} />
                      );
                    })}
                  </div>
                </div>
              )}

              {activeTab === "webbuilder" && (
                <div className="skill-box">
                  <div className="grid gap-4 grid-cols-12">
                    {webBuilderSkills.map((val, i) => {
                      return <Skill key={i} id={val.id} title={val.title} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
