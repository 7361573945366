import { Link } from "react-scroll";

/*--------------------
* About Section
----------------------*/
export default function About() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto"
                src="img/hero-image.png"
                title="Banner"
                alt="Banner"
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                I'm a No Code Specialist and Freelance Developer
              </h3>
              <p className="text-[16px] md:text-[18px]">
                I am a professional web developer with a passion for creating
                interactive web applications. Despite my familiarity with many
                different technologies, I specialize in No Code development for
                small businesses looking to quickly and easily build a web
                presence. I also offer freelance development services for
                clients looking for a more customized solution.
              </p>

              <div className="pt-6">
                <Link className="px-btn px-btn-theme mr-4" to="contactus">
                  Contact Me
                </Link>
                <Link className="px-btn px-btn-dark" to="portfolio">
                  Portfolio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
