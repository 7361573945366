import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios"; // Assuming you're using axios for HTTP requests

/*--------------------
* Contact Form Component
----------------------*/
export function ContactForm({ handleToast }) {
  const [formStatus, setFormStatus] = useState("IDLE");

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setFormStatus("LOADING");
    try {
      await axios.post("https://nocodedon-backend.vercel.app/api/submitForm", values);
      handleToast("Form submitted successfully!", "success");
      setFormStatus("SUCCESS");
    } catch (error) {
      handleToast("There was an issue submitting your form.", "error");
      setFormStatus("ERROR");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full bg-slate-700 p-8">
      <h3 className="mb-4 text-4xl text-white">Get in Touch</h3>
      <p className="text-white mb-4">
        Have a project you'd like to discuss?<br/>
        Drop a line below and I&apos;ll be sure to get back to you as soon as I
        can.
      </p>
      {/* This is the container */}
      {formStatus === "IDLE" && (
        <Formik
          initialValues={{ email: "", subject: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="space-y-6 md:space-y-8">
              <div className="md:flex md:space-x-4 space-y-6 md:space-y-0">
                <div className="flex-grow">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-500"
                  >
                    Your email
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="p-1.5 text-sm shadow-sm bg-white border border-gray-600 text-gray-700 focus:ring-primary-500 focus:border-primary-500 block w-full placeholder-gray-400"
                    placeholder="name@domain.com"
                    required
                  />
                </div>
                <div className="flex-grow">
                  <label
                    htmlFor="subject"
                    className="block mb-2 text-sm font-medium text-gray-500"
                  >
                    Subject
                  </label>
                  <Field
                    type="text"
                    id="subject"
                    name="subject"
                    className="p-1.5 text-sm block w-full text-gray-700 bg-white border border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 placeholder-gray-400"
                    placeholder="What services do you need?"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-500"
                >
                  Your message
                </label>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  rows="6"
                  className="p-1.5 text-sm block w-full text-gray-700 bg-white border border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 placeholder-gray-400"
                  placeholder="Leave a comment..."
                />
              </div>
              <button
                type="submit"
                className="px-btn px-btn-theme"
                disabled={isSubmitting}
              >
                Send message
              </button>
            </Form>
          )}
        </Formik>
      )}
      {formStatus === "LOADING" && (
        <div className="relative h-40">
          <div className="absolute top-0 left-0 w-full h-full bg-slate-700 flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
          </div>
        </div>
      )}
      {formStatus === "SUCCESS" && (
        <div className="relative h-40">
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-slate-700 flex justify-center items-center z-10">
            <div className="flex flex-col items-center space-y-4 p-8 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
              <p className="text-lg text-white">Form submitted successfully!</p>
            </div>
          </div>
        </div>
      )}
      {formStatus === "ERROR" && (
        <div className="relative h-40">
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-slate-700 flex justify-center items-center">
            <div className="flex flex-col items-center space-y-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9 7a1 1 0 112 0v4a1 1 0 11-2 0V7zm1 9a1 1 0 100-2 1 1 0 000 2z"
                />
              </svg>
              <p className="text-lg text-white text-center md:w-[80%]">
                Sorry, there was an error submitting your form. Please try again
                or contact me directly at nocodedon@gmail.com.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
