/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: { main: "bg-1", icon: "fas fa-laptop" },
      title: "Web Development",
      description:
        "I offer a wide range of professional web development services at an affordable price to help your business attract more visitors and keep them on your site. \nExample: You need a simple website displaying your company information and offering. \nSolution: I can build you a responsive, fast, and SEO friendly website.",
    },
    {
      id: { main: "bg-2", icon: "fas fa-columns" },
      title: "SMM Automation",
      description:
        "I help businesses to automate their business processes and save their time and money on their social media marketing strategy by leveraging no code tools and artificial intelligence. \nExample: You would like to automate the process of generating social media marketing content for your business. \nSolution: I can build a simple integration that can generate social media marketing content for your business.",
    },
    {
      id: { main: "bg-3", icon: "fas fa-ruler-vertical" },
      title: "Chatbot Development",
      description:
        "I build simple to complex chatbots for your business to automate your customer support and increase your sales. \nExample:\n Problem: You need a simple chatbot that can answer your customer's questions and help them to find the right product based on your company specific information. \nSolution: I can build a simple chatbot that can answer your customer's questions and help them to find the right product.",
    },
    {
      id: { main: "bg-4", icon: "fas fa-globe" },
      title: "Workflow Automation",
      description:
        "I help businesses to automate their workflows and save time and money by leveraging no code tools. \nExample:\n Problem: You need a simple web form that can collect data and store it in a database for further processing and automation. \nSolution: I can build a simple web form that can collect data and store it in a database for further processing and automation.",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gray"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>My Services</span>
              </h3>
            </div>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
            {service_list.map((val, i) => {
              return (
                <Service
                  key={i}
                  id={val.id}
                  title={val.title}
                  description={val.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
