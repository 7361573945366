import { useState } from "react";
import { Link } from "react-scroll";

/*--------------------
* Proejct Section
----------------------*/

function Proejct(props) {
  return (
    <>
      <div className="px-modal mfp-hide">
        <div className="single-project-box">
          <div className="grid grid-cols-12 gx-3">
            <div className="col-span-12 md:col-span-7 mb-10 md:mb-0">
              <div className="grid grid-cols-2 gap-2">
                {props.projectDetails.images.map((img, i) => {
                  return (
                    <div key={`image_${i}`} className="col-span-1">
                      <img className="border" src={img} title="" alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-12 md:col-span-5 md:pl-10 lg:pl-14">
              <h4 className="font-[600] text-[25px] text-black mb-4 pb-4 border-b">
                {props.projectDetails.title}
              </h4>
              <p className="mb-3">{props.projectDetails.description}</p>
              <p>{props.projectDetails.subDescription}</p>
              <ul className="m-0 p-0 pt-7 list-none">
                {props.projectDetails.details.map((detail, i) => {
                  return (
                    <li key={`detail_${i}`} className="flex py-2">
                      <span className="w-[100px] font-[600] text-black">
                        {detail.title}:
                      </span>
                      <span>{detail.description}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button className="px-close" onClick={props.closePopup}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Work Section
----------------------*/

export default function Work() {
  const [showModal, setShowModal] = useState(0);
  const closePopup = () => {
    setShowModal(0);
  };

  const projectDetails = [
    {
      title: "Development for Marketing Agency Startup",
      description:
        "Here is an example of a website I built for a marketing agency startup. The website is built with NextJS (ReactJS) and Tailwind CSS. The website is hosted on Netlify. The website is also connected to a chatbot built with Botpress. The chatbot uses context and intents to provide the user with the right information.",
      subDescription:
        "Key features include a booking, onboarding, chatbot, project management system. Radix UI is used for the design system. GSAP is used for the animations.",
      images: [
        "img/portfolio/wnderworks/1.png",
        "img/portfolio/wnderworks/2.png",
        "img/portfolio/wnderworks/3.png",
        "img/portfolio/wnderworks/4.png",
      ],
      details: [
        {
          title: "Type",
          description: "Website",
        },
        {
          title: "Langages",
          description: "Node, NextJS (ReactJS), TypeScript, Tailwind",
        },
        {
          title: "Platform",
          description: "Netlify",
        },
        {
          title: "No Code Tools",
          description: "Airtable, Cal.com, Botpress, Zapier",
        },
        {
          title: "Live URL",
          description: "www.wnderworks.com",
        },
      ],
    },
    {
      title: "Rismo Fitness",
      description:
        "This is a website I built for a fitness startup. The website is built with NextJS (ReactJS) and Tailwind CSS. The website is hosted on Netlify. The website is connected to Airtable for the blog posts and Cal.com for the booking system.",
      subDescription:
        "Key features include a booking, onboarding. Radix UI is incoporated in the design system.",
      images: [
        "img/portfolio/rismo/1.png",
        "img/portfolio/rismo/2.png",
        "img/portfolio/rismo/3.png",
        "img/portfolio/rismo/4.png",
      ],
      details: [
        {
          title: "Type",
          description: "Website",
        },
        {
          title: "Langages",
          description: "NextJS (ReactJS), TypeScript, Tailwind",
        },
        {
          title: "Platform",
          description: "Netflify",
        },
        {
          title: "No Code Tools",
          description: "Airtable, Cal.com",
        },
        {
          title: "Live URL",
          description: "www.rismofitness.com",
        },
      ],
    },
    {
      title: "Salon Website",
      description:
        "This is a salon website mockup I made for a client. The website is built with Webflow. The website is hosted on Webflow. The website follows the Finsweet Client First Style Guidelines and allows for dynamic content generation from Airtable by using Webflows CMS.",
      subDescription:
        "This mockup integrated webflows built in CMS system with Squarespaces booking integration. *Active site no longer holds these features as they are only for premium accounts. If you're interested in seeing the full features, please contact me.",
      images: [
        "img/portfolio/muse-salon/1.png",
        "img/portfolio/muse-salon/2.png",
        "img/portfolio/muse-salon/3.png",
        "img/portfolio/muse-salon/4.png",
      ],
      details: [
        {
          title: "Type",
          description: "Website",
        },
        {
          title: "Langages",
          description: "Finsweet Client First Style Guidelines",
        },
        {
          title: "Platform",
          description: "Webflow",
        },
        {
          title: "No Code Tools",
          description: "Airtable Squarespace Booking",
        },
        {
          title: "Live URL",
          description: "https://webflow.com/design/salon-mockup-bookup",
        },
      ],
    },

    {
      title: "Website Build for Marketing Agency Startup 02",
      description:
        "Here's another agency build put together using Webflows built in CMS. Built in airtable integration.",
      subDescription:
        "This mockup integrated webflows built in CMS system with Squarespaces booking integration. *Active site no longer holds these features as they are only for premium accounts. If you're interested in seeing the full features, please contact me.",
      images: [
        "img/portfolio/bu-agency/1.png",
        "img/portfolio/bu-agency/2.png",
        "img/portfolio/bu-agency/3.png",
        "img/portfolio/bu-agency/4.png",
      ],
      details: [
        {
          title: "Type",
          description: "Website",
        },
        {
          title: "Langages",
          description: "",
        },
        {
          title: "Platform",
          description: "WordPress",
        },
        {
          title: "Country",
          description: "USA",
        },
        {
          title: "Live URL",
          description: "www.example.com",
        },
      ],
    },
  ];
  return (
    <>
      <section
        data-scroll-data="3"
        id="portfolio"
        className="section bg-orange-50"
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Latest Projects</span>
              </h3>
            </div>
          </div>
          <div className="lightbox-gallery portfolio-box">
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img
                    src="img/portfolio/wnderworks/wnderworks-main.png"
                    title=""
                    alt=""
                  />
                  <Link
                    to="www.wnderworks.com"
                    className="gallery-link gallery-link-icon"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Web Development</span>
                  </h6>
                  <h4>Web Development Project - Agency Startup</h4>
                  <p>
                    I design and develop services for customers of all sizes,
                    specializing in creating stylish, modern websites, web
                    services and online stores
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(1)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img
                    src="img/portfolio/rismo/rismo-main.png"
                    title=""
                    alt=""
                  />
                  <Link to="#" className="gallery-link gallery-link-icon">
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Web Development</span>
                  </h6>
                  <h4>RISMO Fitness</h4>
                  <p>
                    This is a website I built for a fitness startup. The website
                    is built with NextJS (ReactJS) and Tailwind CSS. The website
                    is hosted on Netlify. The website is connected to Airtable
                    for the blog posts and Cal.com for the booking system.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(2)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img
                    src="img/portfolio/muse-salon/muse-salon-main.png"
                    title=""
                    alt=""
                  />
                  <Link to="#" className="gallery-link gallery-link-icon">
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Web Development</span>
                  </h6>
                  <h4>Salon Mockup</h4>
                  <p>
                    This is a salon website mockup. The website is built with
                    Webflow. The website follows the Finsweet Client First Style
                    Guidelines and allows for dynamic content generation from
                    Airtable by using Webflows CMS.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(2)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img">
                  <img
                    src="img/portfolio/bu-agency/bu-agency-main.png"
                    title=""
                    alt=""
                  />
                  <Link to="#" className="gallery-link gallery-link-icon">
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Web Development</span>
                  </h6>
                  <h4>Website Development for Marketing Agency Startup</h4>
                  <p>
                    Here's another agency build put together using Webflows
                    built in CMS. Built in airtable integration.
                  </p>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => setShowModal(4)}
                    >
                      View Project{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showModal ? (
        <>
          <Proejct
            closePopup={closePopup}
            projectDetails={projectDetails[showModal - 1]}
          ></Proejct>
        </>
      ) : null}
    </>
  );
}
