import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
    const testimonail_list = [
        {
            id: 1, 
            image: 'img/portfolio/rismo/michael.webp', 
            author_name: 'Michael D.', 
            position: 'Founder of RISMO Fitness', 
            description: "I'm not a tech guy but he made it easy for me to understand what he was doing and why. When people visit my site they always compliment me on how good it looks and how easy it is to navigate."
        },

    ]

    return (
        <>
        <section className="section testimonial-section">
            <div className="container">
                <div className="grid section-heading">
                    <div className="lg:col-span-6 text-center mx-auto">
                        <h3><span>Testimonial</span></h3>
                    </div>
                </div>
                <Swiper 
                    spaceBetween={30} 
                    slidesPerView={2} 
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                        slidesPerView: 1,
                        },
                        992:{
                        slidesPerView:2,
                        },
                    }}
                >
                    {
                        testimonail_list.map((val ,i) =>{
                            return <SwiperSlide key={i}>
                                        <div className="feature-box-03">
                                            <div className="feature-img">
                                                <img src={val.image}  title={val.image} alt={val.image}/>
                                            </div>
                                            <div className="feature-content">
                                                <div className="icons">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <p>{ val.description }</p>
                                                <h6>{ val.author_name }</h6>
                                                <span>{ val.position }</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })
                    }
                </Swiper>
            </div>
        </section>
        </>
    );
}
